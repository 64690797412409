import { IconStar, IconSpace } from '@unique/icons';
import { FC } from 'react';
import cn from 'classnames';

type SpaceMenuIconProps = {
  isPinned: boolean;
  isActive: boolean;
};

export const SpaceMenuIcon: FC<SpaceMenuIconProps> = ({ isPinned, isActive }) => {
  return (
    <div
      className={cn({
        'flex h-8 w-8 items-center justify-center rounded-full': true,
        'bg-secondary-variant group-hover:bg-secondary': !isActive,
        'bg-primary-cta': isActive,
      })}
    >
      {isPinned ? <IconStar /> : <IconSpace includeShadow={true} />}
    </div>
  );
};
