import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { InputSelectOption } from '@unique/component-library';

export interface deprecateCacheInput {
  chatsToDelete: ChatToDelete[];
}

export enum ChatDeletionState {
  DELETING = 'DELETING',
  DELETED = 'DELETED',
}
export interface ChatToDelete {
  chatId: string;
  deletionState: ChatDeletionState;
}

export interface Stream {
  chatId: string;
  messageId: string;
}

// map of contentId to imageUrl
export interface ChatImageUrls {
  [key: string]: string;
}

export interface ChatState {
  chatsToDelete: ChatToDelete[];
  chatImageUrls: ChatImageUrls;
  streams: Stream[];
  translateToLanguage: InputSelectOption;
  textToTranslate: string;
}

const initialState: ChatState = {
  chatsToDelete: [],
  chatImageUrls: {},
  streams: [],
  translateToLanguage: {
    label: 'English',
    value: 'English',
  },
  textToTranslate: '',
};

export interface setChatsToDeleteInput {
  chatsToDelete: ChatToDelete[];
}

export interface setChatImageUrlsInput {
  chatImageUrls: ChatImageUrls;
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatsToDelete: (state, action: PayloadAction<setChatsToDeleteInput>) => {
      state.chatsToDelete = action.payload.chatsToDelete;
    },
    setChatImageUrls: (state, action: PayloadAction<setChatImageUrlsInput>) => {
      state.chatImageUrls = action.payload.chatImageUrls;
    },
    // Deprecate cache using deprecateCachedData action.
    deprecateCache: (state, action: PayloadAction<deprecateCacheInput>) => {
      state.chatsToDelete = [...action.payload.chatsToDelete];
    },
    // Handle streams
    addStream: (state, action: PayloadAction<Stream>) => {
      state.streams.push(action.payload);
    },
    removeStream: (state, action: PayloadAction<Stream>) => {
      // We clean all streams with the same chatId to avoid weird blocked situation with multiple stream.
      // Only affet the stop button UI for now.
      state.streams = state.streams.filter((s) => action.payload.chatId !== s.chatId);
    },
    updateTranslateToLanguage: (state, action: PayloadAction<InputSelectOption>) => {
      state.translateToLanguage = action.payload;
    },
    setTextToTranslate: (state, action: PayloadAction<string>) => {
      state.textToTranslate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export * from './actions';
export default chatSlice.reducer;
