import { CHAT_QUERY_VARIABLES } from '@/lib/constants/chatQueryVariables';
import { getUseChatsQueryKey, useChatQuery, useChatUpdateTitleMutation } from '@/lib/swr/hooks';
import { logger } from '@unique/next-commons/logger';
import { ToastVariant, useToast } from '@unique/shared-library';

const log = logger.child({ package: 'chat', namespace: 'chat:hooks:useChatUpdateTitle' });

export function useChatUpdateTitle(chatId: string) {
  const { showToast } = useToast();
  const { trigger: chatUpdateTitleMutation, isMutating: isRenamingChat } =
    useChatUpdateTitleMutation(getUseChatsQueryKey(CHAT_QUERY_VARIABLES));
  const { mutate: updateChatData } = useChatQuery({
    chatId,
  });

  const updateChatTitle = (title: string, onUpdateChatTitleDone?: () => void) => {
    chatUpdateTitleMutation(
      { chatId, title },
      {
        revalidate: true,
        throwOnError: false,
        onSuccess: () => {
          updateChatData();
          showToast &&
            showToast({
              message: `Chat renamed to ${title}`,
              variant: ToastVariant.SUCCESS,
              duration: 6000,
            });
          onUpdateChatTitleDone && onUpdateChatTitleDone();
        },
        onError: (err) => {
          log.error(err);
          showToast &&
            showToast({
              message: 'There was an error while updating the chat title',
              variant: ToastVariant.ERROR,
            });
          onUpdateChatTitleDone && onUpdateChatTitleDone();
        },
      },
    );
  };

  return { updateChatTitle, isRenamingChat };
}
