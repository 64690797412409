import { logger } from '@unique/next-commons/logger';
import { ChatImageUrls, chatSlice } from '../chatSlice';

const log = logger.child({
  package: 'chat',
  namespace: 'redux:actions:setChatImageUrls',
});

export const setChatImageUrls = (chatImageUrls: ChatImageUrls) => {
  return async (dispatch) => {
    const { setChatImageUrls } = chatSlice.actions;
    try {
      log.info(`dispatch setting chatImageUrls: ${chatImageUrls}`);
      return dispatch(setChatImageUrls({ chatImageUrls: { ...chatImageUrls } }));
    } catch (error) {
      log.error(error.response.errors[0].message);
      return Promise.reject(error.response.errors[0]);
    }
  };
};
