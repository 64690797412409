import { logger } from '../logger';
import { useState, useEffect } from 'react';

const log = logger.child({
  package: 'next-commons',
  namespace: 'helpers:useNetworkStatus',
});

/**
 * Function that checks if the client can reach the server.
 * It does this by sending a request to the "/api/health" endpoint on the server.
 * If the request fails after retrying 3 times, it assumes that the network is down.
 *
 * This method is more reliable than the navigator.onLine check only,
 * which can be inaccurate as it only indicates potential network availability,
 * not actual connectivity to a server.
 *
 * @returns {boolean} The network status. `true` if the client can reach the server, `false` otherwise.
 */
export const useNetworkStatus = (basePath: string) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const maxRetries = 4; // Maximum number of retries
  const backoffDelays = [500, 1000, 2000, 5000]; // Delays in milliseconds

  const checkInternetConnection = async (attempt = 0) => {
    if (navigator.onLine) {
      try {
        const response = await fetch(`${basePath}/api/health`);
        setIsOnline(response.ok);
        log.info('Network is online');
      } catch (error) {
        if (attempt < maxRetries) {
          log.info(`Network is offline, retry attempt ${attempt}`);
          setTimeout(() => {
            checkInternetConnection(attempt + 1);
          }, backoffDelays[attempt]);
        } else {
          setIsOnline(false);
          log.info('Network is offline and max retries reached');
        }
      }
    } else {
      setIsOnline(false);
    }
  };

  useEffect(() => {
    const handleOnline = () => {
      checkInternetConnection();
    };
    const handleOffline = () => {
      log.info('Network is offline');
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Perform an initial check
    checkInternetConnection();

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return isOnline;
};
