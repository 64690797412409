import { User } from 'oidc-client-ts';

export const extractRoles = (user: User): string[] | null => {
  const regex = /^urn:zitadel:iam:org:project:\d+:roles$/;

  for (const key of Object.keys(user.profile)) {
    if (regex.test(key)) return Object.keys(user.profile[key]);
  }

  return null;
};
