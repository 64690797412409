'use client';

import { useAssistantsQuery } from '@/lib/swr/hooks';
import { ButtonIcon, ButtonSize, SecondLevelNavItem } from '@unique/component-library';
import { IconArrowDown, IconNewChat } from '@unique/icons';
import { sortAssistants } from '@unique/shared-library';
import { SortOrder } from '@unique/shared-library/@generated/graphql';
import cn from 'classnames';
import { useParams } from 'next/navigation';
import { FC, useState } from 'react';
import { ChatMenu } from '../Chat/ChatMenu';
import { ExploreSpaceButton } from './ExploreSpaceButton';
import { SpaceMenuIcon } from './SpaceMenuIcon';
import { SpaceMenuSkeleton } from './SpaceMenuSkeleton';

const DEFAULT_SPACES_TO_SHOW = 5;
export const SpaceMenu: FC = () => {
  const [showAllSpaces, setShowAllSpaces] = useState<boolean>(false);
  const { spaceId } = useParams<{ spaceId: string }>();

  const { data: assistantsQueryResult, isLoading } = useAssistantsQuery({
    orderBy: [{ createdAt: SortOrder.Asc }],
  });

  if (isLoading) {
    return <SpaceMenuSkeleton />;
  }

  const handleClickToggleSpaces = () => {
    setShowAllSpaces(!showAllSpaces);
  };

  const spacesToDisplayDefault = sortAssistants(assistantsQueryResult?.assistants)?.slice(
    0,
    DEFAULT_SPACES_TO_SHOW,
  );

  const spacesToDisplayExpanded = sortAssistants(assistantsQueryResult?.assistants)?.slice(
    DEFAULT_SPACES_TO_SHOW,
    assistantsQueryResult?.assistants?.length,
  );

  return (
    <div className="relative h-full">
      <div className="mb-5">
        {/* only show explore space button if user has more than one space */}
        {assistantsQueryResult?.assistants?.length > 1 && (
          <div className="mr-10 w-full p-4">
            <ExploreSpaceButton />
          </div>
        )}

        {!assistantsQueryResult?.assistants?.length && !isLoading && (
          <>
            <div className="subtitle-1 flex items-center p-4">Spaces</div>
            <SecondLevelNavItem
              key="no-assistants"
              name="Your Spaces will show here"
              isActive={true}
              readOnly={true}
            />
          </>
        )}
        {spacesToDisplayDefault?.map((assistant) => (
          <SecondLevelNavItem
            key={assistant.id}
            name={assistant.name ?? 'Untitled Space'}
            href={`/space/${assistant.id}`}
            isActive={spaceId === assistant.id}
            className="group py-3"
            labelClassName="font-bold"
            icon={
              <SpaceMenuIcon
                isPinned={assistant.settings?.isPinned}
                isActive={spaceId === assistant.id}
              />
            }
          >
            {spaceId !== assistant.id && (
              <ButtonIcon
                icon={<IconNewChat />}
                className="ml-auto hidden sm:group-hover:flex"
                buttonSize={ButtonSize.SMALL}
              />
            )}
          </SecondLevelNavItem>
        ))}
      </div>
      {assistantsQueryResult?.assistants?.length > DEFAULT_SPACES_TO_SHOW && (
        <div className="my-4 px-4">
          <button
            className="subtitle-2 bg-secondary-variant text-on-secondary hover:bg-primary-cta flex w-max cursor-pointer items-center gap-x-2 rounded-[4px] px-2 py-0.5 opacity-80 transition hover:opacity-100"
            onClick={handleClickToggleSpaces}
          >
            {!showAllSpaces ? (
              <>
                See {assistantsQueryResult?.assistants?.length - DEFAULT_SPACES_TO_SHOW} more{' '}
                <IconArrowDown />
              </>
            ) : (
              <>
                Show less
                <span className="origin-center rotate-180">
                  <IconArrowDown />
                </span>
              </>
            )}
          </button>
        </div>
      )}
      <div
        className={cn({
          'grid transition-[grid-template]': true,
          'grid-rows-[0fr]': !showAllSpaces,
          '!grid-rows-[1fr]': showAllSpaces,
        })}
      >
        <div className="overflow-hidden">
          {spacesToDisplayExpanded?.map((assistant) => (
            <SecondLevelNavItem
              key={assistant.id}
              name={assistant.name ?? 'Untitled Space'}
              href={`/space/${assistant.id}`}
              isActive={spaceId === assistant.id}
              className="group !py-3"
              labelClassName="font-bold"
              icon={
                <SpaceMenuIcon
                  isPinned={assistant.settings?.isPinned}
                  isActive={spaceId === assistant.id}
                />
              }
            >
              {spaceId !== assistant.id && (
                <ButtonIcon
                  icon={<IconNewChat />}
                  className="ml-auto hidden sm:group-hover:flex"
                  buttonSize={ButtonSize.SMALL}
                />
              )}
            </SecondLevelNavItem>
          ))}
        </div>
      </div>
      <div className="border-secondary-variant mt-3 border-t pt-3">
        <ChatMenu title="My Chats" />
      </div>
    </div>
  );
};
