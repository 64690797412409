'use client';

import { useChatUpdateTitle } from '@/hooks/useChatUpdateTitle';
import { CHAT_TITLE_MAX_LENGTH } from '@/lib/constants/chatTitleMaxLength';
import { Chat } from '@/lib/swr/types';
import { ButtonIcon, ButtonType, InputField, Modal } from '@unique/component-library';
import { FC, MutableRefObject, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

interface ChatRenameModalProps {
  chat: Chat;
  onClose: () => void;
}

type ChatRenameForm = {
  title: string;
};
export const ChatRenameModal: FC<ChatRenameModalProps> = ({ chat, onClose }) => {
  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm<ChatRenameForm>({
    defaultValues: {
      title: chat.title,
    },
    reValidateMode: 'onChange',
  });
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);
  const { updateChatTitle, isRenamingChat } = useChatUpdateTitle(chat.id);

  useEffect(() => {
    if (inputEl) {
      inputEl?.focus();
    }
  }, [inputEl]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async ({ title }: ChatRenameForm) => {
    updateChatTitle(title, handleClose);
  };

  return (
    <Modal shouldShow handleClose={handleClose}>
      <form className="max-w-[800px] lg:min-w-[700px]" onSubmit={handleSubmit(onSubmit)}>
        <h2 className="title-s text-on-background-main mb-6">Rename Chat</h2>
        <InputField
          register={register}
          name="title"
          classes="!font-normal"
          autoFocus
          errorMessage={errors?.title?.message}
          maxLength={CHAT_TITLE_MAX_LENGTH}
          setRef={(ref: MutableRefObject<HTMLInputElement | null>) => {
            setInputEl(ref?.current);
          }}
        />
        <div className="mt-6 flex justify-end">
          <ButtonIcon
            type={ButtonType.SUBMIT}
            isLoading={isRenamingChat}
            disabled={!isDirty || !isValid}
          >
            Rename
          </ButtonIcon>
        </div>
      </form>
    </Modal>
  );
};
