import Skeleton from '@unique/component-library/src/Skeleton';
import { FC } from 'react';

export const SpaceMenuSkeleton: FC = () => {
  return (
    <>
      <div className="mt-2 px-4">
        <Skeleton className="!bg-secondary-variant mb-8 !h-10"></Skeleton>
        {Array(5)
          .fill('')
          .map((_, i) => (
            <div className="mb-8 flex items-center gap-x-3" key={`space-menu-skeleton-${i}`}>
              <Skeleton className="!bg-secondary-variant !h-6 !w-6 shrink-0 !rounded-full"></Skeleton>
              <Skeleton className="!bg-secondary-variant !h-6"></Skeleton>
            </div>
          ))}
      </div>
      <div className="border-secondary-variant mt-6 border-t px-4 pt-9">
        <Skeleton className="!bg-secondary-variant mb-4 !h-6" />
        <Skeleton className="!bg-secondary-variant mb-4 !h-6 !w-10/12" />
        <Skeleton className="!bg-secondary-variant mb-4 !h-6 !w-1/2" />
        <Skeleton className="!bg-secondary-variant mb-4 !h-6 !w-3/4" />
        <Skeleton className="!bg-secondary-variant mb-4 !h-6 !w-11/12" />
        <Skeleton className="!bg-secondary-variant mb-4 !h-6 !w-10/12" />
        <Skeleton className="!bg-secondary-variant mb-4 !h-6 !w-1/2" />
        <Skeleton className="!bg-secondary-variant mb-4 !h-6 !w-full" />
        <Skeleton className="!bg-secondary-variant mb-4 !h-6 !w-3/4" />
      </div>
    </>
  );
};
