import { logger } from '@unique/next-commons/logger';
import { ChatToDelete, chatSlice } from '../chatSlice';

const log = logger.child({
  package: 'chat',
  namespace: 'redux:actions:setChatsToDelete',
});

export const setChatsToDelete = (chatsToDelete: ChatToDelete[]) => {
  return async (dispatch) => {
    const { setChatsToDelete } = chatSlice.actions;
    try {
      log.info(`dispatch setting chatsToDelete: ${chatsToDelete.join(', ')}`);
      return dispatch(
        setChatsToDelete({
          chatsToDelete: [...chatsToDelete],
        }),
      );
    } catch (error) {
      log.error(error.response.errors[0].message);
      return Promise.reject(error.response.errors[0]);
    }
  };
};
