'use client';

import * as Sentry from '@sentry/nextjs';
import { Spinner } from '@unique/component-library';
import { getCompanyId } from '@unique/shared-library';
import { FC, useContext, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { serializeError } from 'serialize-error';
import { useNetworkStatus } from '../helpers/useNetworkStatus';
import { logger } from '../logger';
import { ErrorHandlerContext } from '../swr';

interface RequireAuthProps {
  children: React.ReactNode;
  basePath: string;
}

const log = logger.child({
  package: 'next-commons',
  namespace: 'oidc-auth:require-auth',
});

export const RequireAuth: FC<RequireAuthProps> = ({ children, basePath }) => {
  const auth = useAuth();
  const { setError, setLogout } = useContext(ErrorHandlerContext);
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const isOnline = useNetworkStatus(basePath);

  // isRedirecting avoid blinking effect when loading login page
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Detect past errors and avoid inifinite loop on login page
  const [hasAnErrorOccured, setHasAnErrorOccured] = useState(
    sessionStorage.getItem('hasAnErrorOccured') === 'true'
  );

  // 1. Initial login if JWT is not valid or not present.
  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      setHasTriedSignin(true);
      if (auth.user?.refresh_token) {
        log.info('JWT is not valid, but refresh_token is present. Trying to refresh token.');
        auth.signinSilent();
      } else {
        // We don't log an object here, as it will be wiped during the redirect.
        log.info(`User unauthenticated. Starting login flow. Existing user: ${!!auth.user}`);
        auth.signinRedirect({ login_hint: auth.user?.profile?.email });
      }
    }
  }, [auth, hasTriedSignin]);

  useEffect(() => {
    setLogout(() => auth.signoutRedirect);
  }, [auth]);

  useEffect(() => {
    if (hasAnErrorOccured) {
      sessionStorage.setItem('hasAnErrorOccured', 'true');
    }
  }, [hasAnErrorOccured]);

  useEffect(() => {
    if (Sentry.isInitialized()) {
      if (!auth.isAuthenticated) {
        Sentry.setUser(null);
        return;
      }
      Sentry.setUser(null);
      Sentry.setUser({
        email: auth.user?.profile?.email,
        ip_address: '{{auto}}',
        companyId: getCompanyId(auth.user),
      });
    }
  }, [auth.user]);

  // 2. Refresh token if JWT is expired.
  useEffect(() => {
    const dispose = auth.events.addAccessTokenExpiring(() => {
      auth.signinSilent().catch((error) => {
        log.error(`Silent sign-in failed with error: ${JSON.stringify(serializeError(error))}`);
      });
    });

    return () => {
      dispose();
    };
  }, [auth.events, auth.signinSilent]);

  // 3. Try to fix invalid_grant error (refresh_token is invalid) using the redirect flow.
  useEffect(() => {
    // wait for network to be back to be able to handle the error and redirect the user to signin page
    if (!auth.error || !isOnline) return;

    // We don't log an object here, as it will be wiped during the redirect.
    log.warn(
      `Error occurred during login flow. Error: ${JSON.stringify(serializeError(auth.error))}`,
    );

    // clear the state from the url
    window.history.replaceState({}, document.title, window.location.pathname);

    if (!hasAnErrorOccured && auth.error.message.includes('invalid_grant')) {
      log.info(
        `Trying to fix invalid_grant error using the redirect flow. Existing user: ${!!auth.user}`,
      );
      auth.signinRedirect({ login_hint: auth.user?.profile?.email });
      setIsRedirecting(true);
      setHasAnErrorOccured(true);
    } else if (!hasAnErrorOccured && auth.error.message.includes('No matching state found in storage')) {
      log.info(
        `Trying to fix no matching state found in storage error using the redirect flow. Existing user: ${!!auth.user}`,
      );
      auth.signinRedirect({ login_hint: auth.user?.profile?.email });
      setIsRedirecting(true);
      setHasAnErrorOccured(true);
    } else if (!hasAnErrorOccured && auth.error.message.includes('Failed to fetch')) {
      // We do not handle lost of network at auth level and let the app handle it.
      // It will redirect to login page on auth.removeUser() and show the no internet page.
      log.info(
        `Trying to fetch auth data but raised 'Failed to fetch' exception. Existing user: ${!!auth.user}`,
      );
      auth.signinRedirect({ login_hint: auth.user?.profile?.email });
      setIsRedirecting(true);
      setHasAnErrorOccured(true);
    } else if (!hasAnErrorOccured && auth.error.message.includes('Errors.User.RefreshToken.Invalid')) {
      log.info(
        `Trying to fix invalid refresh token error using the redirect flow. Existing user: ${!!auth.user}`,
      );
      auth.signinRedirect({ login_hint: auth.user?.profile?.email });
      setIsRedirecting(true);
      setHasAnErrorOccured(true);
    } else {
      sessionStorage.removeItem('hasAnErrorOccured');
      setError(auth.error);
    }

    // clear localStorage
    auth.removeUser();
  }, [auth.error, isOnline]);

  if (auth.isLoading || isRedirecting) {
    return (
      <div className="absolute left-0 top-0 flex h-screen w-full items-center justify-center">
        <Spinner size={24} />
      </div>
    );
  }

  return <>{children}</>;
};
