'use client';

import { ForbiddenPage } from '@unique/shared-library';
import { FC } from 'react';
import { useAuth } from 'react-oidc-context';
import { Roles, extractRoles, hasAllRequiredRoles, hasAnyRequiredRoles } from '../authorization';

interface PageAccessProps {
  hasAllRoles?: Roles[];
  hasAnyRoles?: Roles[];
  children: React.ReactNode;
}

export const PageAccess: FC<PageAccessProps> = ({ hasAllRoles, hasAnyRoles, children }) => {
  const auth = useAuth();

  // Authorization Checks
  let allowAccess = false;

  if (auth.isAuthenticated) {
    const roles = extractRoles(auth.user) ?? [];
    if (hasAnyRoles && hasAnyRoles.length > 0)
      allowAccess = hasAnyRequiredRoles(roles, hasAnyRoles);
    if (hasAllRoles && hasAllRoles.length > 0)
      allowAccess = hasAllRequiredRoles(roles, hasAllRoles);
  }

  if (!auth.isAuthenticated || !allowAccess) {
    return <ForbiddenPage />;
  }

  return <>{children}</>;
};
